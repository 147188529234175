/*-------------------------
    FAQ
-------------------------*/
.faq-area {
    h1 {
        color: #727885;
    }
    .section-heading {
        .title {
            font-size: 48px;
            margin-bottom: 40px;
            @media only screen and (max-width: 575px) {
                font-size: 38px;
            }
        }
        
        p {
            width: 100%;
        }
    }
}

.faq-accordion {
    .accordion-item {
        background-color: var(--dark-main-body);
        border-radius: 24px;
        border: none;
        margin-bottom: 20px;
        box-shadow: none;
        transition: var(--transition);
        &:last-child {
            margin-bottom: 0;
            .accordion-button.collapsed {
                margin-bottom: 0;
            }
        }
    }
   
    .accordion-button {
        box-shadow: none;
        font-size: 24px;
        color: var(--color-primary);
        font-weight: 500;
        font-family: var(--font-primary);
        padding: 30px 40px 20px;
        position: relative;
        border-radius: 0 !important;
        transition: var(--transition);
        background-color: transparent;
        span {
            margin-right: 5px;
        }

        &.collapsed {
            color: #727885;
            border-radius: 24px !important;
            padding: 30px 40px;
            background-color: #f8f8f8;
            margin-bottom: -10px;
            &::after {
                color: var(--dark-body-text);
            }
        }
    }
    .accordion-body {
        padding: 0 40px 40px;
        p {
            color: white;
            margin-bottom: 0;
        }
    }
}