/*-------------------------
    Privacy Policy
-------------------------*/

.italic {
    font-style: italic;
}

.privacy-policy-area {
    .section-title {
        margin-bottom: 60px;
        .title {
            border-bottom: 3px solid var(--color-primary);
            padding-bottom: 15px;            
        }
    }
    h4 {
        margin-bottom: 20px;        
    }
    p {
        line-height: 1.7;        
    }
    
    ul {
        padding-left: 20px;
        margin-bottom: 40px;
        li {
            margin-top: 10px;
            margin-bottom: 10px;
            line-height: 28px;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

.privacy-accordion {
    margin-top: 20px;
    margin-bottom: 20px;
    .accordion-item {        
        border-radius: 24px;
        border: none;
        margin-bottom: 20px;
        box-shadow: none;
        transition: var(--transition);
        &:last-child {
            margin-bottom: 0;
            .accordion-button.collapsed {
                margin-bottom: 0;
            }
        }
    }
   
    .accordion-button {
        box-shadow: none;
        font-size: 24px;
        
        font-weight: 500;
        font-family: var(--font-primary);
        padding: 30px 40px 20px;
        position: relative;
        border-radius: 0 !important;
        transition: var(--transition);
        background-color: transparent;
        span {
            margin-right: 5px;
        }

        &.collapsed {
            color: #727885;
            border-radius: 24px !important;
            padding: 30px 40px;
            background-color: #f8f8f8;
            margin-bottom: -10px;
            &::after {
                color: var(--dark-body-text);
            }
        }
    }
    .accordion-body {
        padding: 0 40px 40px;
        p {
            color: white;
            margin-bottom: 0;
        }
    }
}



/* Extra large devices (large desktops, 2000px and up) */
@media (max-width: 2000px) {

}

/* Extra large devices (large desktops, 1500px and up) */
@media (max-width: 1500px) {


}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {

}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {


}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {

  
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px)  {
  
}