/*-------------------------
    Buttons
-------------------------*/

.action-btn {
    
  position: relative;
  overflow: hidden;
  font-weight: 700;
  background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
  backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid rgba(255, 255, 255, 0.1);

  
  span {
      background: linear-gradient(264.28deg, #DEC7FF -38.2%, #5C27FE 103.12%);
      padding: 10px 20px;
      border-radius: 10px;
      color: #fff;

      transition: all 0.3s ease-in-out;
      position: relative;
      overflow: hidden;

      &::after {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
          content: "";
          height: 200%;
          left: -210%;
          opacity: 0;
          position: absolute;
          top: -50%;
          transition: all 0.7s ease 0s;
          width: 200%;
      }
  }


  &:hover {
      span {
          &::after {
              left: -30%;
              opacity: 1;
              top: -20%;
              transition-duration: 0.7s, 0.7s, 0.15s;
              transition-property: left, top, opacity;
              transition-timing-function: linear;
          }
      }
  }

}

.action-btn.s1 {
  span {
      background: linear-gradient(264.28deg, rgba(255, 255, 255, 0) -38.2%, rgba(255, 255, 255, 0.1) 103.12%);
  }
}

.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    &.btn-borderd {
        border: var(--border-gray);
        color: var(--color-dark);
        background-color: transparent;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
        }
    }
    &.btn-fill-primary {
        background-color: var(--color-primary);
        color: var(--color-white);
        border: 1px solid var(--color-primary);
        overflow: hidden;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: var(--color-link);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: var(--color-link);
            border-color: var(--color-link);
            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-white {
        background-color: var(--color-white);
        box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
        &:hover {
            background-color: var(--color-green);
            color: var(--color-white);
        }
    }
    &.btn-large {
        padding: 23px 55px;
        @media only screen and (max-width: 991px) {
            padding: 18px 35px;
        }
    }
    &.btn-large-fluid {
        padding: 23px;
        width: 100%;
    }
    &.btn-fluid {
        width: 100%;
    }
}


.login-btn {
    user-select: none;
    -moz-user-select: none;
    background: #040e18;
    border-radius: 10px;
    border: 2px solid transparent;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 0;
    padding: 15px 15px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.4s 0s;
    vertical-align: middle;
    white-space: nowrap;
    box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
    background-clip: padding-box;
    position: relative;
  }
  .login-btn::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: -2px;
    background: linear-gradient(
      90deg,
      #6b2bd9 0%,
      #040c15 49%,
      #6b2bd9 100%
    );
    border-radius: 10px;
    transition: all 0.4s ease 0s;
    z-index: -1;
  }
  .login-btn:hover {
    border-color: #6b2bd9;
    color: #6b2bd9;
    background: #040e18;
    transition: ease-in 0.4s;
  }

  .signup-btn {
    user-select: none;
    -moz-user-select: none;
    background: #040e18;
    border-radius: 10px;
    border: 2px solid transparent;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 0;
    padding: 15px 15px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.4s 0s;
    vertical-align: middle;
    white-space: nowrap;
    box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
    background-clip: padding-box;
    position: relative;
  }
  .signup-btn::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: -2px;
    background: linear-gradient(
      90deg,
      #6b2bd9 0%,
      #040c15 49%,
      #6b2bd9 100%
    );
    border-radius: 10px;
    transition: all 0.4s ease 0s;
    z-index: -1;
  }
  .signup-btn:hover {
    border-color: #6b2bd9;
    color: #6b2bd9;
    background: #040e18;
    transition: ease-in 0.4s;
  }

  .kwirl__nav-btn {
    gap: 10px;
  }