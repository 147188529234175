@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    height: 100vh;
}

/* create blog post text editor */
.ql-editor {
    font-size: 1.05rem;
}