.about-expert {
    text-align: center;
    position: relative;
    .thumbnail {
        position: relative;
        display: inline-block;
        border-radius: 40px;
        box-shadow: 0px 17px 26px 0px rgba(0,0,0,0.2);
        img {
            border-radius: 40px;
        }
        .popup-video {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            .play-btn {
                height: 150px;
                width: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgb(253, 0, 253); 
                border: none;
                border-radius: 50%;
                margin: 0 auto;
                font-size: 32px;
                color: white;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: white;
                    color: rgb(253, 0, 253);                    
                }

            }
        }
    }}